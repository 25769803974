export const FETCH_WEATHER_REQUEST = "FETCH_WEATHER_REQUEST",
  FETCH_WEATHER_SUCCESS = "FETCH_WEATHER_SUCCESS",
  FETCH_WEATHER_FAILED = "FETCH_WEATHER_FAILED",
  FETCH_INITIAL_WEATHER_REQUEST = "FETCH_INITIAL_WEATHER_REQUEST",
  FETCH_INITIAL_WEATHER_SUCCESS = "FETCH_INITIAL_WEATHER_SUCCESS",
  FETCH_INITIAL_WEATHER_FAILED = "FETCH_INITIAL_WEATHER_FAILED";

export function fetchWeather(city) {
  return {
    type: FETCH_WEATHER_REQUEST,
    payload: { city },
  };
}

export function fetchInitialWeather(city) {
  return {
    type: FETCH_INITIAL_WEATHER_REQUEST,
    payload: { city },
  };
}
